import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import { Link, Element } from 'react-scroll';


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, setTotalSupply] = useState(0);

  const [mintAmountAL1, setMintAmountAL1] = useState(1);
  const [mintAmountAL2, setMintAmountAL2] = useState(1);
  const [mintAmountPub, setMintAmountPub] = useState(1);

  const [alMintLimit1, setAlMintLimit1] = useState(0);
  const [alMintLimit2, setAlMintLimit2] = useState(0);
  const [publicMintLimit, setPublicMintLimit] = useState(0);

  const [freeClaimed, setFreeClaimed] = useState(0);
  const [alClaimed1, setAlClaimed1] = useState(0);
  const [alClaimed2, setAlClaimed2] = useState(0);
  const [publicClaimed, setPublicClaimed] = useState(0);

  const [claimingFreeNft, setClaimingFreeNft] = useState(false);
  const [claimingAlNft1, setClaimingAlNft1] = useState(false);
  const [claimingAlNft2, setClaimingAlNft2] = useState(false);
  const [claimingNft, setClaimingNft] = useState(false);

  const [feedbackFree, setFeedbackFree] = useState(``);
  const [feedbackAl1, setFeedbackAl1] = useState(``);
  const [feedbackAl2, setFeedbackAl2] = useState(``);
  const [feedback, setFeedback] = useState(``);
  
  const [freeSaleStart, setFreeSaleStart] = useState(false);
  const [preSaleStart1, setPreSaleStart1] = useState(false);
  const [preSaleStart2, setPreSaleStart2] = useState(false);
  const [pubSaleStart, setPubSaleStart] = useState(false);

  const [FreemintProof, setFreemintProof] = useState("");
  const [FreemintNum, setMintNum] = useState("");
  
  const [AlProof1, setAlProof1] = useState("");
  const [AlProof2, setAlProof2] = useState("");
  

  // config
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_FREE_COST: 0,
    WEI_AL1_COST: 0,
    WEI_AL2_COST: 0,
    WEI_COST: 0,
    DISPLAY_AL1_COST: 0,
    DISPLAY_AL2_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };


  //totalSupply
  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply()
      .call()
      .then((receipt) => {
        setTotalSupply(receipt - 100);
        dispatch(fetchData(blockchain.account));
      });
    }
  };


  // saleState
  const checkFreeSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .freeSaleStart()
      .call()
      .then((receipt) => {
        setFreeSaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPreSaleStart1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .al1SaleStart()
      .call()
      .then((receipt) => {
        setPreSaleStart1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPreSaleStart2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .al2SaleStart()
      .call()
      .then((receipt) => {
        setPreSaleStart2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPubSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .pubSaleStart()
      .call()
      .then((receipt) => {
        setPubSaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };


  // limit
  const checkAlMintLimit1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .al1MintLimit()
      .call()
      .then((receipt) => {
        setAlMintLimit1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkAlMintLimit2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .al2MintLimit()
      .call()
      .then((receipt) => {
        setAlMintLimit2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublicMintLimit = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicMintLimit()
      .call()
      .then((receipt) => {
        setPublicMintLimit(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };


  //claimed
  const checkFreeClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .freeClaimed(blockchain.account)
      .call()
      .then((receipt) => {
        setFreeClaimed(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkAlClaimed1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .al1Claimed(blockchain.account)
      .call()
      .then((receipt) => {
        setAlClaimed1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkAlClaimed2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .al2Claimed(blockchain.account)
      .call()
      .then((receipt) => {
        setAlClaimed2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublicClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicClaimed(blockchain.account)
      .call()
      .then((receipt) => {
        setPublicClaimed(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };


  // freeMintProof
  const getFreemintList = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/freemint.json");
      let validNuns = whitelist.map((addr) => addr.slice(-64));//slice　Nun
      let adresses = whitelist.map((addr) => addr.slice(0, -64));//slice Address
      let index = adresses.indexOf(address);
      let validNun = validNuns[index];

      let mintNum = 0;
      if (validNun !== undefined) {
        mintNum = parseInt(validNun, 16);
      }

      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address + validNun);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();

      //console.log('freeMint:hasheBefore/' + address);//確認したらコメントアウト
      //console.log('freeMint:hashed/' + hashedAddress);//確認したらコメントアウト
      //console.log("freeMint:proof/" + proof);//確認したらコメントアウト
      console.log("freeMint:root/" + root);//確認したらコメントアウト
      console.log("freeMint:count/" + mintNum);//確認したらコメントアウト

      setFreemintProof(proof);
      setMintNum(mintNum);
      dispatch(fetchData(blockchain.account));
    }
  };

  // alProof
  const getAl1 = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/al1.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      //console.log('AL1:hasheBefore/'+address);//確認したらコメントアウト
      //console.log('AL1:hashed/'+hashedAddress);//確認したらコメントアウト
      //console.log("AL1:proof/" + proof);//確認したらコメントアウト
      console.log("AL1:root/" + root);//確認したらコメントアウト
      setAlProof1(proof);
      dispatch(fetchData(blockchain.account));
    }
  };

  const getAl2 = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/al2.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      //console.log('AL2:hasheBefore/'+address);//確認したらコメントアウト
      //console.log('AL2:hashed/'+hashedAddress);//確認したらコメントアウト
      //console.log("AL2:proof/" + proof);//確認したらコメントアウト
      console.log("AL2:root/" + root);//確認したらコメントアウト
      setAlProof2(proof);
      dispatch(fetchData(blockchain.account));
    }
  };


  // mint
  const claimFreeNFTs = async () => {
    let cost = CONFIG.WEI_FREE_COST;
    let totalCostWei = String(cost * FreemintNum);
    // ミント関数のガス代を計算
    let method = await blockchain.smartContract.methods.freeMint(FreemintNum, FreemintNum, FreemintProof);
    setFeedbackFree(`Minting...`);
    setClaimingFreeNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      });
      console.log(receipt);
      setFeedbackFree(
        `Mint success!!`
      );
      setClaimingFreeNft(false);
      checkFreeClaimed();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedbackFree("Try again");
      setClaimingFreeNft(false);
    }
  };

  const claimAlNFTs1 = async () => {
    let cost = CONFIG.WEI_AL1_COST;
    let totalCostWei = String(cost * mintAmountAL1);
    // ウォレットの残高を取得
    let balance = await blockchain.web3.eth.getBalance(blockchain.account);
    let balanceWei = Number(balance);
    if (balanceWei < totalCostWei) {
      // ウォレットの残高が足りない場合、ユーザーに通知
      alert("ウォレットの残高が不足しています。");
      return;
    }
    // ミント関数のガス代を計算
    let method = await blockchain.smartContract.methods.preMint1(mintAmountAL1, AlProof1);
    setFeedbackAl1(`Minting...`);
    setClaimingAlNft1(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      });
      console.log(receipt);
      setFeedbackAl1(
        `Mint success!!`
      );
      setClaimingAlNft1(false);
      checkAlClaimed1();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedbackAl1("Try again");
      setClaimingAlNft1(false);
    }
  };

  const claimAlNFTs2 = async () => {
    let cost = CONFIG.WEI_AL2_COST;
    let totalCostWei = String(cost * mintAmountAL2);
    // ウォレットの残高を取得
    let balance = await blockchain.web3.eth.getBalance(blockchain.account);
    let balanceWei = Number(balance);
    if (balanceWei < totalCostWei) {
      // ウォレットの残高が足りない場合、ユーザーに通知
      alert("ウォレットの残高が不足しています。");
      return;
    }
    // ミント関数のガス代を計算
    let method = await blockchain.smartContract.methods.preMint2(mintAmountAL2, AlProof2);
    setFeedbackAl2(`Minting...`);
    setClaimingAlNft2(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      });
      console.log(receipt);
      setFeedbackAl2("Mint success!!");
      setClaimingAlNft2(false);
      checkAlClaimed2();
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
    } catch (err) {
      console.log(err);
      setFeedbackAl2("Try again");
      setClaimingAlNft2(false);
    }
  };

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmountPub);
     // ウォレットの残高を取得
     let balance = await blockchain.web3.eth.getBalance(blockchain.account);
     let balanceWei = Number(balance);
     if (balanceWei < totalCostWei) {
       // ウォレットの残高が足りない場合、ユーザーに通知
       alert("ウォレットの残高が不足しています。");
       return;
     }
     // ミント関数のガス代を計算
      let method = await blockchain.smartContract.methods.publicMint(mintAmountPub);
      setFeedback(`Minting...`);
      setClaimingNft(true);
      try {
        let receipt = await method.send({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        });
        console.log(receipt);
        setFeedback(
          `Mint success!!`
        );
        setClaimingNft(false);
        checkPublicClaimed();
        checkTotalSupply();
        dispatch(fetchData(blockchain.account));
      } catch (err) {
        console.log(err);
        setFeedback("Try again");
        setClaimingNft(false);
      }
    };

  const decrementAlMintAmount1 = () => {
    let newMintAmountAL1 = mintAmountAL1 - 1;
    if (newMintAmountAL1 < 1) {
      newMintAmountAL1 = 1;
    }
    setMintAmountAL1(newMintAmountAL1);
  };

  const incrementAlMintAmount1 = () => {
    let newMintAmountAL1 = mintAmountAL1 + 1;
    if (newMintAmountAL1 > (alMintLimit1 - alClaimed1)) {
      newMintAmountAL1 = alMintLimit1 - alClaimed1;
    }
    //0より小さい場合は０にする
    if(newMintAmountAL1 < 0){
      newMintAmountAL1 = 0;
    }
    setMintAmountAL1(newMintAmountAL1);
  };

  const decrementAlMintAmount2 = () => {
    let newMintAmountAL2 = mintAmountAL2 - 1;
    if (newMintAmountAL2 < 1) {
      newMintAmountAL2 = 1;
    }
    setMintAmountAL2(newMintAmountAL2);
  };

  const incrementAlMintAmount2 = () => {
    let newMintAmountAL2 = mintAmountAL2 + 1;
    if (newMintAmountAL2 > (alMintLimit2 - alClaimed2)) {
      newMintAmountAL2 = alMintLimit2 - alClaimed2;
    }
    //0より小さい場合は０にする
    if(newMintAmountAL2 < 0){
      newMintAmountAL2 = 0;
    }
    setMintAmountAL2(newMintAmountAL2);
  };

  const decrementPublicMintAmount = () => {
    let newMintAmountPub = mintAmountPub - 1;
    if (newMintAmountPub < 1) {
      newMintAmountPub = 1;
    }
    setMintAmountPub(newMintAmountPub);
  };

  const incrementPublicMintAmount = () => {
    let newMintAmountPub = mintAmountPub + 1;
    if (newMintAmountPub > (publicMintLimit - publicClaimed)) {
      newMintAmountPub = publicMintLimit - publicClaimed;
    }
    //0より小さい場合は０にする
    if(newMintAmountPub < 0){
      newMintAmountPub = 0;
    }
    setMintAmountPub(newMintAmountPub);
  };


  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkAlMintLimit1();
    checkAlMintLimit2();
    checkPublicMintLimit();
    checkFreeClaimed();
    checkAlClaimed1();
    checkAlClaimed2();
    checkPublicClaimed();
    checkFreeSaleStart();
    checkPreSaleStart1();
    checkPreSaleStart2();
    checkPubSaleStart();
    getFreemintList();
    getAl1();
    getAl2();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkAlMintLimit1();
    checkAlMintLimit2();
    checkPublicMintLimit();
    checkFreeClaimed();
    checkAlClaimed1();
    checkAlClaimed2();
    checkPublicClaimed();
    checkFreeSaleStart();
    checkPreSaleStart1();
    checkPreSaleStart2();
    checkPubSaleStart();
    getFreemintList();
    getAl1();
    getAl2();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container ai={"center"}  flex={1}>

      <s.key>
        <div style={{width: "100%"}}>
          <s.SpacerLarge/>
          <s.LogoArea>
            <s.Logo src="/config/images/grinpeace_logo_padded_bk.png" alt="LOGO"></s.Logo>
          </s.LogoArea>
        </div>
        <s.MintBottun>
          <Link to="Mint" smooth={true} duration={600}>
            <s.StyledButtonTopMint>Mint</s.StyledButtonTopMint>
          </Link>
        </s.MintBottun>
        <div style={{textAlign:"center", width:"100%"}}>   
          <s.NavBar>
            <Link to="About" smooth={true} duration={600}><s.NavMenu>About</s.NavMenu></Link>
            <Link to="Character" smooth={true} duration={600}><s.NavMenu>Character</s.NavMenu></Link>
            <Link to="Roadmap" smooth={true} duration={600}><s.NavMenu>Roadmap</s.NavMenu></Link>
            <Link to="Team" smooth={true} duration={600}><s.NavMenu>Team</s.NavMenu></Link>
          </s.NavBar>
        </div>
        <div style={{textAlign:"center", width:"100%"}}>    
          <img style={{width:"90%",margin:"32px 0px 54px"}} src="/config/images/mv_img.png" alt="key"/>
        </div>

        <s.Container ai={"center"} jc={"center"} style={{padding: "0px 0px 48px", fontSize: "16px", fontWeight: "600", color:"rgba(0, 0, 0, 1)"}}>
          2023.11.23 Thu. 12:00~
        </s.Container>
      </s.key>

    
        <Element name="Mint" className="Mint"></Element>
        <s.Container ai={"center"} jc={"center"} style={{ maxWidth:"1280px", background: "rgba(67, 184, 155, 1)", flexWrap: "wrap", padding: "48px", fontSize: "20px", fontWeight: "600", color:"#fff"}}>
          Mint grin peace
        </s.Container>
        <div style={{textAlign:"center", width:"100%",maxWidth:"1280px" ,background: "rgba(67, 184, 155, 1)",paddingBottom:"32px"}}> 
          <div style={{maxWidth: "640px", textAlign:"center", margin:"0 auto"}}> 
            <s.ResponsiveZone>
              <div>
                <img className="top" style={{ width: "90%", maxHeight: "320px", maxWidth: "320px", paddingBottom:"8px"}} src="/config/images/mint-character.jpg" alt="mint-character image" />
                <img className="top" style={{ width: "90%", maxHeight: "320px", maxWidth: "320px", paddingBottom:"8px"}} src="/config/images/sale2.png" alt="sale image" />
              </div>
              <s.MintZoneBottun>
                <s.Container flex={1} ai={"center"} style={{padding: "8px", maxHeight:"240px"}}>
                  <>
                  <div style={{ fontSize: "26px", color: "#fff", fontWeight:"600",letterSpacing: "0.08em"}}>
                    Thank you!!
                  </div>
                    {blockchain.account === "" || blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.SpacerSmall />
                        <div style={{ fontSize: "18px", color: "#fff", fontWeight:"600",letterSpacing: "0.08em"}}>
                        Looking forward to the reveal!!
                            </div>
                            <div style={{padding: "20px 12px 12px", textDecoration: "none"}}><a href="https://opensea.io/collection/grin-peace" target="_blank" rel="noopener"><img src="/config/images/os.png" style={{width:"36px"}} alt="OpenSea logo" /></a></div>
                     
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.TextMsg>
                              {blockchain.errorMsg}
                            </s.TextMsg>
                          </>
                        ) : null}
                      </s.Container>
                      ) : (
                        <>
                          <s.Container ai={"center"} jc={"center"}>
                            <s.SpacerXSmall/>
                            {/*
                            <s.StyledButtonClamed
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                              }}
                            >
                              CONNECTED
                            </s.StyledButtonClamed>
                            */}
                            <s.WalletAddress>
                              {truncate(blockchain.account, 4)}{truncate(blockchain.account.slice(-4), 4)}
                            </s.WalletAddress>    
                          </s.Container>
                        </>
                      )}
                    </>
                  </s.Container>


                  {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
                        <div>
                          <s.TextMsg style={{fontSize:"22px",marginBottom:"12px",fontWeight:"600"}}>Sold out!! Thank you!!</s.TextMsg>
                          <s.StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                            <img style={{ width: "32px",marginTop:"4px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
                          </s.StyledLink>
                        </div>
                      ) : (

                  <s.MintPhaseResponsive>

                  {/* ------------------------ Freemint ------------------------ */}
                  <div>
                        <>
                          {blockchain.account === "" ||
                          blockchain.smartContract === null ? (
                            <s.Container ai={"center"} jc={"center"}>                    
                              {blockchain.errorMsg !== "" ? (
                                <></>
                              ) : null}
                            </s.Container>
                          ) : (
                            <>
                            <div style={{ margin: '0px 8px'}}>
                              <s.TextMintPhase style={{marginTop:"2px"}}>Free Mint</s.TextMintPhase>
                              {/*<s.TextMintPeriod>11/23(木・祝) 12:00 ~ 11/27(月) 23:59</s.TextMintPeriod>*/}
                            </div>
                              {FreemintProof != "" ? (//FreeMint確認
                                <div>
                                  {freeSaleStart == true ? (
                                  <div>
                                  {freeClaimed > FreemintNum - 1 ? (
                                    <div>
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.StyledButtonClamed
                                          disabled={1}//claimingFreeNftがtrueなら disabledを表示させる。＝クリックできない
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          Max Minted
                                        </s.StyledButtonClamed>
                                      </s.Container>
                                    </div>
                                  ) : (
                                    <div>
                                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                        <s.StyledButton
                                          disabled={claimingAlNft1 ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                          onClick={(e) => {
                                            e.preventDefault();
                                            claimFreeNFTs();
                                            getData();
                                          }}
                                        >
                                        {claimingFreeNft ? "Mint now" : "Free mint"}
                                        </s.StyledButton>
                                        
                                      </s.Container>

                                    <s.TextMsg>{FreemintNum}mint</s.TextMsg>
                                      {freeClaimed > 0 ? (
                                          <></>                              
                                      ):(
                                      <></>
                                      )}
                                    </div>
                                  )}
                                  </div>
                                ) : (
                                  <>
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButtonClamed>
                                      You have Free Mint
                                    </s.StyledButtonClamed>
                                  </s.Container>
                                    <s.TextMsg>{FreemintNum}mint</s.TextMsg>  
                                  </>
                                )} 
                              </div>
                              ) : (
                              <div>
                                <s.TextMsg>Not Freemint</s.TextMsg>
                              </div>
                              )} 
                            </>
                          )}
                        </>
                      <s.TextMsg>{feedbackFree}</s.TextMsg>
                    </div>

                    {/* -------------------- Public -------------------- */}
                    <div>
                      <>
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                          <s.Container ai={"center"} jc={"center"}>                
                            {blockchain.errorMsg !== "" ? (
                              <>
                              </>
                            ) : null}
                          </s.Container>
                        ) : (
                          <div>
                            <div style={{ margin: '0px 8px'}}>
                              <s.TextMintPhase>Public Sale / {CONFIG.DISPLAY_COST}ETH</s.TextMintPhase>
                              {/*<s.TextMintPeriod>11/25(土) 12:00 ~ 11/27(月) 23:59</s.TextMintPeriod>*/}
                            </div>
                            {pubSaleStart == true ? (
                              <div>
                              {publicClaimed > publicMintLimit - 1 ? (
                                <div>
                                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <s.StyledButtonClamed
                                  disabled={1}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  Max Minted
                                </s.StyledButtonClamed>
                                </s.Container>
                                </div>
                              ) : (
                                <>
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledRoundButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementPublicMintAmount();
                                      }}
                                    >
                                      -
                                    </s.StyledRoundButton>

                                    <s.TextMsg>{mintAmountPub}</s.TextMsg>

                                    <s.StyledRoundButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementPublicMintAmount();
                                      }}
                                    >
                                      +
                                    </s.StyledRoundButton>
                                    </s.Container>

                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButton
                                      disabled={claimingNft ? 1 : 0}//claimingNftsがtrueなら disabledを表示させる。＝クリックできない
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                    {claimingNft ?  "Mint now" : "Public Mint"}
                                    </s.StyledButton>
                                  </s.Container>

                                  <s.SpacerXSmall/>

                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={() => {
                                        // ボタンが押されたらmintAmountPubの値に基づいてリンク先に新しいタブで遷移
                                        window.open(`https://paypiement.xyz/ja/projects/8cdd2764-badb-4254-86d9-6a0f606291fd?quantity=${mintAmountPub}&recipientAddress=${blockchain.account}`, '_blank');
                                      }}
                                    >
                                      Mint (円決済)
                                    </s.StyledButton>
                                  </s.Container>

                                <s.TextMsg>Max {publicMintLimit}mint</s.TextMsg>
                                  {publicClaimed > 0 ? (
                                      <></>                              
                                  ):(
                                  <></>
                                  )}
                                </>
                              )}
                              </div>
                            ) : (
                              <>
                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <s.StyledButtonClamed>
                                  Please wait
                                </s.StyledButtonClamed>
                                </s.Container>

                                <s.TextMsg>Max {publicMintLimit}mint</s.TextMsg>
                              </>                            
                            )}
                        </div>
                      )}
                      </>
                    <s.TextMsg>{feedback}</s.TextMsg>
                  </div>
                </s.MintPhaseResponsive>
                )}
              </s.MintZoneBottun>
            </s.ResponsiveZone>
          </div>
        </div>

        <s.Container ai={"center"} jc={"center"} style={{ background: "rgba(67, 184, 155, 0)", flexWrap: "wrap", padding: "64px 0px 24px", fontSize: "20px", fontWeight: "600", color:"#000"}}>
          PV
        </s.Container>
        <s.PV>
          <s.InnerPV src="https://www.youtube.com/embed/7k6mvU2GA2A?si=4i3uf41oQdC70sWT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></s.InnerPV>
        </s.PV>
     
        <Element name="About" className="About"></Element>
        <div style={{textAlign:"center", width:"100%", background:"rgba(255, 255, 0, 0)", marginTop:"16px"}}> 
          <div style={{ maxWidth: "760px", background: "rgba(255, 0, 0, 0)", padding: "12px", boxSizing: "border-box", margin:"0 auto"}}>

          <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "24px 0px 8px", fontSize: "32px", fontWeight: "600", color:"rgba(67, 184, 155, 1)"}}>
            About
          </s.Container>
          <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "0px 0px 32px", fontSize: "12px", fontWeight: "600", color:"#000"}}>
            グリンピースについて
          </s.Container>

            <s.ResponsiveZoneAbout>
              <s.AboutZone>
                <s.Column3>
                  grin peaceはYO-CO、Aoyoko、叶野 舞、アルパちゃんの
                  <br />4人の女性クリエイターチーム「YAMA」のプロジェクトです。
                  <br />それぞれが作ったキャラクターを主人公にした物語を主軸に、
                  <br />NFTや絵本、雑貨を展開していきます。
                  <br />ワクワクドキドキ、大人も子どもも楽しめるストーリーと
                  <br />世界中の人々に愛されるキャラクターを目指しています。
                  <br /><br />
                </s.Column3>
                <s.Column3>
                <s.RordmapTextP>grin peace is a project by YAMA, </s.RordmapTextP><s.RordmapTextP>a team of four female</s.RordmapTextP>
                <br />creators: YO-CO, Aoyoko, Mai Kano, and Alpa-chan.
                <br />The main focus of the project is a story featuring the
                <br />characters created by each of them,
                <br />NFT, picture books, and miscellaneous goods will be
                <br />developed based on the stories featuring the characters
                <br />created by each of them.
                <br /><s.RordmapTextP>We aim to create exciting </s.RordmapTextP><s.RordmapTextP>and thrilling stories that both</s.RordmapTextP>
                <br /><s.RordmapTextP>children and adults can enjoy, </s.RordmapTextP><s.RordmapTextP>as well as characters that are</s.RordmapTextP>
                <br />loved by people all over the world.
                <br />and characters that will be loved by people all over the world.
                </s.Column3>
              </s.AboutZone>

              <s.ReverseColumn1>
                <img className="top" style={{ width: "100%", maxHeight: "320px", maxWidth: "320px"}} src="/config/images/about.png" alt="about"/>
              </s.ReverseColumn1>

            </s.ResponsiveZoneAbout>

          </div>  
        </div>


        <Element name="Character" className="Character"></Element>
        <div style={{maxWidth: "740px",textAlign:"left", width:"100%", background:"rgba(255, 255, 0, 0)", marginTop:"24px"}}>
          <div style={{padding: "12px", boxSizing: "border-box"}} >
            <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "12px 0px 8px", fontSize: "32px", fontWeight: "600", color:"rgba(67, 184, 155, 1)"}}>
              Character
            </s.Container>
            <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "0px 0px 32px", fontSize: "12px", fontWeight: "600", color:"#000"}}>
              グリンピースのキャラクターたち
            </s.Container>

            <s.CharacterZone>
              <s.CharacterZoneResponsive>
                <s.CharacterBackground>
                  <img style={{ maxWidth: "100%" }} src="/config/images/hanabi.png" alt="Hanabi" />
                  <s.CharacterName>ハナビ</s.CharacterName>
                  <s.CharacterNameAlphabet>Hanabi</s.CharacterNameAlphabet>
                  <s.CharacterExplanation>
                    <s.CharacterExplanationAreaFlex>
                      <s.CharacterAttributeArea>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>属　性</s.ExplanationBottun>
                          <s.CharacterType>ねこ</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                        <div style={{margin:"6px"}}></div>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>性　別</s.ExplanationBottun>
                          <s.CharacterType>女の子</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                      </s.CharacterAttributeArea>
                      <s.CharacterFeatures>
                        <div>お調子者</div>
                        <div>好奇心旺盛で楽観的</div>
                        <div>お茶目でイタズラ好き</div>
                        <div>考える前に行動する直感的な子</div>
                        <div>思ったことははっきりズバッと言う</div>
                      </s.CharacterFeatures>
                    </s.CharacterExplanationAreaFlex>
                  </s.CharacterExplanation>
                </s.CharacterBackground>

                <s.CharacterMargin></s.CharacterMargin>

                <s.CharacterBackground>
                  <img style={{ maxWidth: "100%" }} src="/config/images/chercher.png" alt="Chercher" />
                  <s.CharacterNameChercher>リドル・キャット・シェルシェル</s.CharacterNameChercher>
                  <s.CharacterNameAlphabet>Riddle•Cat•Chercher</s.CharacterNameAlphabet>
                  <s.CharacterExplanation>
                    <s.CharacterExplanationAreaFlex>
                      <s.CharacterAttributeArea>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>属　性</s.ExplanationBottun>
                          <s.CharacterType>猫板</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                        <div style={{margin:"6px"}}></div>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>性　別</s.ExplanationBottun>
                          <s.CharacterType>男の子</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                      </s.CharacterAttributeArea>
                      <s.CharacterFeatures>
                        <div>思慮深い</div>
                        <div>見守るタイプ</div>
                        <div>何を考えてるか謎</div>
                        <div>実は何も考えていなかったり</div>
                        <div>危機回避に長けている</div>
                      </s.CharacterFeatures>
                    </s.CharacterExplanationAreaFlex>
                  </s.CharacterExplanation>
                </s.CharacterBackground>
              </s.CharacterZoneResponsive>
            </s.CharacterZone>

            <s.CharacterMargin2></s.CharacterMargin2>

            <s.CharacterZone>
              <s.CharacterZoneResponsive>
                <s.CharacterBackground>
                  <img style={{ maxWidth: "100%" }} src="/config/images/knot.png" alt="Knot" />
                  <s.CharacterName>ノット</s.CharacterName>
                  <s.CharacterNameAlphabet>Knot</s.CharacterNameAlphabet>
                  <s.CharacterExplanation>
                    <s.CharacterExplanationAreaFlex>
                      <s.CharacterAttributeArea>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>属　性</s.ExplanationBottun>
                          <s.CharacterType>とり</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                        <div style={{margin:"6px"}}></div>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>性　別</s.ExplanationBottun>
                          <s.CharacterType>男の子</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                      </s.CharacterAttributeArea>
                      <s.CharacterFeatures>
                        <div>頼れる性格</div>
                        <div>知的で勤勉</div>
                        <div>論理的に考える</div>
                        <div>たまにボソッと親父ギャグを言う</div>
                        <div>感情豊かだけどあまり表情に出ない</div>
                      </s.CharacterFeatures>
                    </s.CharacterExplanationAreaFlex>
                  </s.CharacterExplanation>
                </s.CharacterBackground>

                <s.CharacterMargin></s.CharacterMargin>

                <s.CharacterBackground>
                  <img style={{ maxWidth: "100%" }} src="/config/images/luca.png" alt="Luca" />
                  <s.CharacterName>ルカ</s.CharacterName>
                  <s.CharacterNameAlphabet>Luca</s.CharacterNameAlphabet>
                  <s.CharacterExplanation>
                    <s.CharacterExplanationAreaFlex>
                      <s.CharacterAttributeArea>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>属　性</s.ExplanationBottun>
                          <s.CharacterType>アルパカ</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                        <div style={{margin:"6px"}}></div>
                        <s.CharacterAttributeAreaFlex>
                          <s.ExplanationBottun>性　別</s.ExplanationBottun>
                          <s.CharacterType>男の子</s.CharacterType>
                        </s.CharacterAttributeAreaFlex>
                      </s.CharacterAttributeArea>
                      <s.CharacterFeatures>
                        <div>素直で楽観的</div>
                        <div>ハッピーを好む</div>
                        <div>なんでも肯定してくれる</div>
                        <div>考えをはっきり言うがトゲがない</div>
                        <div>疲れてても倒れるまで気づかない</div>
                      </s.CharacterFeatures>
                    </s.CharacterExplanationAreaFlex>
                  </s.CharacterExplanation>
                </s.CharacterBackground>
              </s.CharacterZoneResponsive>
            </s.CharacterZone>

          </div>
        </div>
    

        <Element name="Roadmap" className="Roadmap"></Element>
        <div style={{maxWidth: "720px",textAlign:"left", width:"100%", background:"rgba(255, 255, 0, 0)", marginTop:"32px"}}>
          <div style={{padding: "12px", boxSizing: "border-box"}} >
            <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "12px 0px 8px", fontSize: "32px", fontWeight: "600", color:"rgba(67, 184, 155, 1)"}}>
              Roadmap
            </s.Container>
            <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "0px 0px 32px", fontSize: "12px", fontWeight: "600", color:"#000"}}>
              これからの活動
            </s.Container>

              <s.RordmapText>                
                <s.RordmapTextP>YAMAの活動の目標のひとつとして、</s.RordmapTextP><s.RordmapTextP>絵本づくりがあります。</s.RordmapTextP><br />
                <br /><s.RordmapTextP>グリンピースジェネラティブの販売後、 </s.RordmapTextP><s.RordmapTextP>まずは絵本制作にチャレンジしていきます。</s.RordmapTextP>
                <br /><s.RordmapTextP>その他NFT外の人にも知ってもらえるように、 </s.RordmapTextP><s.RordmapTextP>Instagram、 YouTubeでの読み聞かせ絵本、</s.RordmapTextP>
                <br /><s.RordmapTextP>イベントや展覧会などでのグッズ販売も行っていく予定です。</s.RordmapTextP><br />
                <br /><s.RordmapTextP>NFTを飛び出してフィジカルで一般の方にも</s.RordmapTextP><s.RordmapTextP>アプローチしながら、 世代を問わずに愛される</s.RordmapTextP>
                <br /><s.RordmapTextP>キャラクターたちを目指します。</s.RordmapTextP><s.RordmapTextP>ぜひ応援よろしくお願いします!</s.RordmapTextP>
                <br /><br />
                <br /><s.RordmapTextP>One of the goals of YAMA's activities is </s.RordmapTextP><s.RordmapTextP>to create picture books.</s.RordmapTextP>
                <br />
                <br /><s.RordmapTextP>After selling GrinPeace Generative, </s.RordmapTextP><s.RordmapTextP>we will first try our hand at picture book production.</s.RordmapTextP>
                <br /><s.RordmapTextP>In addition, we plan to read picture </s.RordmapTextP><s.RordmapTextP>books on Instagram and YouTube and sell goods at</s.RordmapTextP>
                <br /><s.RordmapTextP>events and exhibitions so that people </s.RordmapTextP><s.RordmapTextP>outside the NFT will also be aware of our activities.</s.RordmapTextP>
                <br />
                <br /><s.RordmapTextP>We aim to create characters that will </s.RordmapTextP><s.RordmapTextP>be loved by people of all ages, </s.RordmapTextP><s.RordmapTextP>while reaching out to the</s.RordmapTextP>
                <br /><s.RordmapTextP>general public with physical products </s.RordmapTextP><s.RordmapTextP>outside of NFT. We look forward to your support!</s.RordmapTextP>
              </s.RordmapText>
              
              <div style={{padding:"24px 24px 42px",display: "flex", justifyContent: "center", alignItems: "center" }}>
                <s.CharacterImg src="/config/images/roadmap.png" alt="character" />
              </div>
          </div>
        </div>

        <div style={{width:"100%", maxWidth:"720px",padding:"54px 0px 20px",textAlign:"center",backgroundColor:"#D9EEEB",color:"#42B89B", fontWeight:"600",fontSize:"24px"}}>collaboration</div>
        <div style={{maxWidth:"720px", padding:"12px 0px 32px",display: "flex", justifyContent: "center", alignItems: "center" ,backgroundColor:"#D9EEEB"}}>
          <s.CollaborateImg src="/config/images/collaboration.png" alt="collaborate" />
        </div>
    

        <Element name="Team" className="Team"></Element>
        <div style={{maxWidth: "720px",textAlign:"center", width:"100%", background:"rgba(255, 255, 0, 0)", margin:"32px 0px 32px"}}>
          <div style={{padding: "12px", boxSizing: "border-box"}} >
            <s.Container ai={"center"} jc={"center"} style={{ flexWrap: "wrap", padding: "12px 0px 32px", fontSize: "32px", fontWeight: "600", color:"rgba(67, 184, 155, 1)"}}>
              Team
            </s.Container>

            
            <div style={{maxWidth: "720px", width:"100%", display: "flex", justifyContent: "center", margin:"0 auto"}}>
              
                <s.Member>
                  <a href="https://twitter.com/yoco_nft" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>
                    <s.MemberImg src="/config/images/yoco.png" alt="collaborate" />
                  </a>
                  <s.MemberInformation>Founder</s.MemberInformation>
                  <s.MemberName><a href="https://twitter.com/yoco_nft" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>YO-CO</a></s.MemberName>
                </s.Member>
              
                <s.Member>
                  <a href="https://twitter.com/Aoyoko_NFT" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>
                    <s.MemberImg src="/config/images/aoyoko.png" alt="collaborate" />
                  </a>
                  <s.MemberInformation>Founder</s.MemberInformation>
                  <s.MemberName><a href="https://twitter.com/Aoyoko_NFT" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>Aoyoko</a></s.MemberName>
                </s.Member>
              
                <s.Member>
                  <a href="https://twitter.com/mai_sketch" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>
                    <s.MemberImg src="/config/images/mai.png" alt="collaborate" />
                  </a>
                  <s.MemberInformation>Founder</s.MemberInformation>
                  <s.MemberName><a href="https://twitter.com/mai_sketch" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>Mai Kano</a></s.MemberName>
                </s.Member>
              
                <s.Member>
                  <a href="https://twitter.com/alpaca_go_go" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>
                    <s.MemberImg src="/config/images/alpachan.png" alt="collaborate" />
                  </a>
                  <s.MemberInformation>Founder</s.MemberInformation>
                  <s.MemberName><a href="https://twitter.com/alpaca_go_go" target="_blank" rel="noopener"  style={{color:"#000", textDecoration:"none"}}>Alpa chan</a></s.MemberName>
                </s.Member>
              
            </div>
          </div>
        </div>

          <div style={{margin:"0 auto"}}>
            <div style={{display: "flex"}}>
              <div style={{padding: "17px 12px 25px", textDecoration: "none"}}><a href="https://twitter.com/yama_nft" target="_blank" rel="noopener"><img src="/config/images/x.png" style={{width:"13px"}} alt="X logo" /></a></div>
              <div style={{padding: "12px", textDecoration: "none"}}><a href="https://www.instagram.com/yama_stories" target="_blank" rel="noopener"><img src="/config/images/Instagram.png" style={{width:"24px"}} alt="Instagram logo" /></a></div>
              <div style={{padding: "12px", textDecoration: "none"}}><a href="https://opensea.io/collection/grin-peace" target="_blank" rel="noopener"><img src="/config/images/OpenSea.png" style={{width:"24px"}} alt="OpenSea logo" /></a></div>
              <div style={{padding: "12px", textDecoration: "none"}}><a href="https://discord.com/invite/mRhUJN3uAP" target="_blank" rel="noopener"><img src="/config/images/Discord.png" style={{width:"24px"}} alt="Discord logo" /></a></div>
            </div>
          </div>
          <s.SpacerLarge/>

          <s.TextDescription style={{margin:"0 auto", color:"#000",fontSize: "13px"}}>
            Copyright ©︎ 2023 - YAMA
          </s.TextDescription>
          <s.SpacerLarge/>

      </s.Container>
    </s.Screen>
  );
}
export default App;