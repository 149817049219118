import styled from "styled-components";

// top ----------------------------------
export const Screen = styled.div`
  font-family: 'Fredoka', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  background-color: #fff;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const top = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const key = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 780px;
  background-image: url(/config/images/mv_bg.png);
`;

export const LogoArea = styled.div`
  width: 300px;
  margin: 0 auto;
  @media (max-width: 560px) {
    width: 260px;
  }
  @media (max-width: 500px) {
    width: 240px;
  }
  @media (max-width: 460px) {
    width: 200px;
  }
  @media (max-width: 420px) {
    width: 165px;
  }
`;

export const Logo = styled.img`
  max-height: 80px;
  width: 100%;
  margin: 0 auto;
`;

export const MintBottun = styled.div`
  height: 24px;
  border-radius: 30px;
  position: absolute;
  margin-top: -62px;
  right: 0;
  margin-right: 32px;
  @media (max-width: 560px) {
    margin-top: -56px;
    margin-right: 16px;
  }
  @media (max-width: 500px) {
    margin-top: -54px;
  }
  @media (max-width: 460px) {
    margin-top: -48px;
  }
  @media (max-width: 420px) {
    margin-top: -42px;
  }
  @media (max-width: 380px) {
    margin-right: 10px;
  }
  @media (min-width: 1281px) {
    margin-right: 100px;
  }
  @media (min-width: 1436px) {
    margin-right: 200px;
  }
  @media (min-width: 1656px) {
    margin-right: 360px;
  }
  @media (min-width: 1961px) {
    margin-right: 500px;
  }
  @media (min-width: 2201px) {
    margin-right: 660px;
  }
  @media (min-width: 2571px) {
    margin-right: 800px;
  }
  @media (min-width: 2861px) {
    margin-right: 900px;
  }
  @media (min-width: 3021px) {
    margin-right: 1000px;
  }
`;

export const MintMenu = styled.div`
  text-align: center;
  padding: 20px;
`;

export const StyledButtonTopMint = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-radius: 30px;
  border: none;
  background-color: rgb(67, 184, 155);
  font-weight: 600;
  color: #fff;
  width: 84px;
  height: 34px;
  cursor: pointer;
  @media (max-width: 460px) {
    font-size: 13px;
    width: 80px;
    height: 30px;
    padding: 9px;
  }
`;

export const NavBar = styled.div`
  padding-top: 12px;
  display: flex;
  justify-content: center; 
  margin: 0 auto;
  text-decoration: none;
  @media (max-width: 530px) {
    padding-top: 8px;
  }
`;

export const NavMenu = styled.p`
  font-size: 15px;
  margin: 24px;
  textDecoration: none;
  font-weight: 500;
  color: #000;
  @media (max-width: 530px) {
    margin: 12px;
    font-size: 13px;
  }
`;

// mint -------------------------------
export const ResponsiveZone = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 4200px) { //ここでミントエリアのレスポンシブ調整
    display: block;
  }
`;

export const MintPhaseResponsive = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 8px;
  @media (max-width: 4200px) { //ここでミントエリアのレスポンシブ調整
    display: block;
  }
`;

export const StyledButton = styled.button`
  font-family: 'Fredoka', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 14px;
  padding: 10px;
  border-radius: 30px;
  border: solid 1px #fff;
  background-color: rgb(67, 184, 155);
  font-weight: 700;
  letter-spacing: 0.08em;
  color: var(--secondary-text);
  width: 180px;
  cursor: pointer;
  box-shadow: 0px 6px 2px -2px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0px 3px 0px -2px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 2px 6px 0px -2px rgba(255, 255, 255, 0.3);
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover{
    transform: translate3d(0, 2px, 0);
    box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  border-radius: 100%;
  border: solid 1px #fff;
  background-color: rgb(67, 184, 155);
  padding: 6px;
  margin: 10px;
  font-size: 14px;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 2px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 2px 4px 0px -2px rgba(250, 250, 250, 0.3);
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover{
    transform: translate3d(0, 2px, 0);
    box-shadow: none;
  }
`;

export const StyledButtonClamed = styled.button`
  font-family: 'Fredoka', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.08em;
  font-size: 14px;
  padding: 10px;
  border-radius: 30px;
  border: solid 1px #509886;
  background-color: #509886;
  font-weight: 600;
  color: #B2DED4;
  width: 180px;
  cursor: pointer;
  }
`;


export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

export const MintZoneBottun = styled.div`
  padding: 24px 0px;
  @media (max-width: 720px) {
    margin: 0 auto;
    max-width: 720px; //ここでミントエリアのレスポンシブ調整
    padding: 24px 8px 42px;
  }
`;

// about ------------------------------
export const ResponsiveZoneAbout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const AboutZone = styled.div`
  @media (max-width: 720px) {
    order:2;
    max-width: 420px;
    padding: 12px 0px 36px;
    margin: 0 auto;
  }
`;

export const ReverseColumn1 = styled.div`
    @media (max-width: 720px) {
      padding-top: 0px;
      order:1;
    }
`;

export const Column1 = styled.div`
  text-align: left;
  color: var(--accent-text);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  @media (max-width: 720px) {
  }
`;

export const Column2 = styled.div`
  text-align: left;
  color: var(--accent-text);
  font-size: 12px;
  @media (max-width: 720px) {
  }
`;

export const Column3 = styled.div`
  line-height: 1.6;
  text-align: left;
  color: var(--accent-text);
  padding: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  @media (max-width: 720px) {
    padding-top: 16px;
    margin: 0 auto;
    text-align: center;
    max-width: 420px;
    font-size: 12px;
  }
  @media (max-width: 405px) {
    padding-top: 16px;
    margin: 0 auto;
    text-align: center;
    max-width: 420px;
    font-size: 11px;
  }
  @media (max-width: 370px) {
    padding-top: 16px;
    margin: 0 auto;
    text-align: center;
    max-width: 420px;
    font-size: 11px;
  }
`;

// pv ---------------------------------------------------------
export const PV = styled.div`
  max-width: 720px;
`;

export const InnerPV = styled.iframe`
  width: 540px;
  height: 300px;
  @media (max-width: 600px) {
    width: 405px;
    height: 225px;
  }
  @media (max-width: 400px) {
    width: 305px;
    height: 170px;
  }
`;

// character --------------------------------------------------
export const CharacterZoneResponsive = styled.div`
  display: flex; 
  max-width: 740px;
  margin: 0 auto;
  @media (max-width: 720px) {
    display: block; 
    margin: 0 auto;
  }
`;

export const CharacterZone = styled.div`
  max-width: 740px;
  margin: 0 auto;
  @media (max-width: 720px) {
    margin:12px;
  }
`;

export const CharacterBackground = styled.div`
  margin: 0 auto;
  max-width: 360px;
  padding: 24px 12px;
  background-color: rgb(248,244,231);
  @media (max-width: 720px) {
    padding: 0px 8px 24px;
  }
`;

export const CharacterMargin = styled.div`
  margin: 16px;
  @media (max-width: 720px) {
    margin: 12px;
  }
`;

export const CharacterMargin2 = styled.div`
  margin: 32px;
  @media (max-width: 720px) {
    margin: 0px;
  }
`;

export const CharacterName = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 4px;
  @media (max-width: 645px) {
  }
`;

export const CharacterNameChercher = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 4px;
  @media (max-width: 645px) {
  }
`;

export const CharacterNameAlphabet = styled.div`
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #8B8B8B;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 8px;
`;

export const CharacterExplanation = styled.div`
  font-size: 10px;
`;

export const CharacterExplanationAreaFlex = styled.div`
  display: flex;
  padding: 16px 8px;
`;

export const CharacterAttributeArea = styled.div`
  padding-top: 8px;
  border-right: 1px solid #DCDAD0;
  flex-basis: 40%;
  font-weight: 600;
`;

export const CharacterAttributeAreaFlex = styled.div`
  display: flex;
`;

export const ExplanationBottun = styled.div`
  background-color: #fff;
  padding: 7px 12px;
  margin-bottom: 8px;
  border-radius: 16px;
  @media (max-width: 390px) {
    border-right: none;
    font-size: 10px;
  }
`;

export const CharacterType = styled.div`
  padding: 7px 12px 8px 8px;
  @media (max-width: 390px) {
  }
`;

export const CharacterFeatures = styled.div`
  padding-left: 16px;
  flex-basis: 60%;
  line-height: 2.05;
  font-weight: 600;
  @media (max-width: 720px) {
  }
`;

//roed map -----------------------------
export const RordmapText = styled.div`
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  line-height: 1.8;
  text-align: center;
  color: var(--accent-text);
  padding: 0px;
  font-size: 13px;
  font-weight: 600;
  @media (max-width: 720px) {
    margin: 0 auto;
    font-size: 12px;
  }
`;

export const RordmapTextP = styled.p`
  display: inline-block;
`;

export const CharacterImg = styled.img`
  max-width: 640px;
  @media (max-width: 640px) {
    max-width: 95%;
  }
`;

// collabo --------------------------------
export const CollaborateImg = styled.img`
  max-width: 75%;
`;

// team -----------------------------------
export const Member = styled.div`
  max-width: 126px;
  text-align: center;
  width: 100%;
  margin: 0px 20px;
  font-size: 12px;
  @media (max-width: 720px) {
    padding: 0px;
  }
  @media (max-width: 420px) {
    padding: 0px;
    margin: 12px;

  }
`;

export const MemberImg = styled.img`
  max-width: 100%;
  border-radius: 90%;
  @media (max-width: 720px) {
    max-width: 100%;
  }
`;

export const MemberInformation = styled.div`
  margin: 8px 0px 6px;
  fontSize: 12px;
`;

export const MemberName = styled.div`
  margin: 0 auto;
  fontSize: 12px;
`;

// text
export const TextDescription = styled.p`
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
`;

export const TextMsg = styled.p`
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 14px;
  }
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

export const TextMintPhase = styled.p`
  margin: 24px 0px 8px;
  padding-top: 24px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  border-top: solid 1px #ACE5D7;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 20px;
  }
  @media (max-width: 580px) {
    font-size: 18px;
  }
`;

export const TextMintPeriod = styled.p`
  margin: 4px 0px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.5px;
  letter-spacing: 0.36px;
  opacity: 1;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 16px;
  }
  @media (max-width: 580px) {
    font-size: 14px;
  }
`;

export const WalletAddress = styled.p`
  color: #fff;
  margin: 12px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 16px;
  }
  @media (max-width: 580px) {
    font-size: 14px;
  }
`;

export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;